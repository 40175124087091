import React from "react";
import Layout from "../../../components/layout";
import Navigation from "../../../components/Navigation";
import Button from "@material-ui/core/Button";
import Icon1 from "./fehlzeitenerfassung_icon1.svg";
import Icon2 from "./fehlzeitenerfassung_icon2.svg";
import Icon3 from "./fehlzeitenerfassung_icon3.svg";
import Icon4 from "./fehlzeitenerfassung_icon4.svg";
import Bild1 from "./Fehlzeitenerfassung_Bild1.png";

export default function Fehlzeitenerfassung() {
    return (
        <Layout parentPath="/" header="Fehlzeitenerfassung">
            <Navigation path="/info/produkte/fehlzeitenerfassung" />
            <div className="produkteContent">
                <p>
                    Beim Vorgehen gegen Schulabsentismus ist eines der wichtigsten Instrumente die <b>frühzeitige Erkennung</b> von Schulverweigerung. Genau da setzt die Software fehlzeitenerfassung.de an: Sie ermöglicht Lehrer*innen und Sozialarbeiter*innen Ursachen und erste Anzeichen der Verweigerung effektiv zu erfassen und zu erkennen, um adäquate Interventionsschritte einzuleiten. So kann eine Chronifizierung der Verweigerung verhindert werden. Außerdem verringert die Software den Verwaltungsaufwand der Schule deutlich, indem sie die Effektivität im Umgang mit Schulverweigerung steigert.
                </p>
                <div className="buttonBar">
                    <Button href="/kostenlos-testen" variant="contained">Jetzt kostenlos testen &nbsp;>>></Button>
                    <Button href="https://fehlzeitenerfassung.de/app" variant="contained">Sofort starten &nbsp;>>></Button>
                </div>
                <div className="produkteIcons">
                    <table className="iconTable">
                        <tr>
                            <td>
                                <img className="produkteIcon" src={Icon1} alt="Icon1" />
                                <p>
                                    Fehlzeitenerfassung.de ist <b>vollständig DSGVO-Konform</b>! Der Schutz personenbezogener Daten hat für uns oberste Priorität, deswegen wurde unsere Software nach dem <a href="https://www.datenschutzzentrum.de/sdm/">Standard-Datenschutzmodell</a> entwickelt.
                                </p>
                            </td>
                            <td>
                                <img className="produkteIcon" src={Icon2} alt="Icon2" />
                                <p>
                                    Wir haben unsere Software auf Grundlage geltender Schulgesetze und der Beschlüsse des Ministeriums für Schule und Weiterbildung entwickelt. Daher können Sie Maßnahmen gegen Schulpflichtverletzungen mit Hilfe von fehlzeitungerfassung.de gesetzeskonform und leicht durchführen und dokumentieren.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img className="produkteIcon" src={Icon3} alt="Icon3" />
                                <p>
                                    Fehlzeitenerfassung.de ist mit allen Schulverwaltungsprogrammen (z.B. Schild-NRW) kompatibel.
                                </p>
                            </td>
                            <td>
                                <img className="produkteIcon" src={Icon4} alt="Icon4" />
                                <p>
                                    Unsere Software ist intuitiv. Sie brauchen keine Schulung, um die Software bedienen zu können.
                                </p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="bild1">
                    <div className="produkteBild1 fz">
                        <img className="produkteBild1 fz" src={Bild1} alt="Bild 1" />
                    </div>
                    <div className="bild1Content">
                        <ul>
                            <li>
                                Fehlzeiten leicht eintragen (Zeitersparnis dank automatischer Aufsummierung der Fehlzeiten für die Zeugnisse)
                            </li>
                            <li>
                                Auswertungen und Analysen der Fehlzeiten erstellen und betrachten
                            </li>
                            <li>
                                Bemerkungen für Schüler*innen eintragen
                            </li>
                            <li>
                                Sich auf ein Schüler-/Elterngespräch vorbereiten (Übersicht zu jedem Schüler/jeder Schülerin mit Fehlzeiten, Bemerkungen und Interventionen für die Schülerakte oder Behörden (z.B. das Jugendamt) mit wenigen Klicks erstellen und ausdrucken)
                            </li>
                            <li>
                                Maßnahmen bei Schulpflichtverletzungen planen, durchführen und dokumentieren (schnellere Reaktionen und Interventionen durch systematische Erfassung der Fehlzeiten möglich)
                            </li>
                            <li>
                                Mit fertigen Briefvorlagen (z.B. Einladung zum Elterngespräch) Zeit sparen und Fehler vermeiden
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="buttonBar">
                    <Button href="/kostenlos-testen" variant="contained">Jetzt kostenlos testen &nbsp;>>></Button>
                    <Button href="https://fehlzeitenerfassung.de/app" variant="contained">Sofort starten &nbsp;>>></Button>
                    {/*<Button href="/info/preise" variant="contained">Unsere Preise</Button>*/}
                </div>
            </div>
        </Layout>
    );
}
